import React from "react";

import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Button,
	Input,
	Text,
} from "@chakra-ui/react";

import { useUIStore } from "../../../contexts/UIStoreContext";
import { observer } from "mobx-react-lite";
import Strings from "../../../resources/Strings";

export const Dialog = observer(() => {
	const uiStore = useUIStore();
	const cancelRef = React.useRef();

	return (
		<AlertDialog
			isOpen={!!uiStore.dialogData}
			onClose={uiStore.clearDialog}
			leastDestructiveRef={cancelRef}
		>
			<AlertDialogOverlay>
				<AlertDialogContent>
					<AlertDialogHeader fontSize="lg" fontWeight="bold">
						{uiStore.dialogData?.title}
					</AlertDialogHeader>
					<AlertDialogBody>
						{uiStore.dialogData
							&& uiStore.dialogData.onChange
							&& uiStore.dialogData.placeholder
							&& uiStore.dialogData.label
							&& (
								<>
									<Text mb="8px">{uiStore.dialogData.label}</Text>
									<Input onChange={uiStore.dialogData.onChange} mb="8px" placeholder={uiStore.dialogData.placeholder} size="sm" />
								</>
							)}
						{uiStore.dialogData?.message}
					</AlertDialogBody>
					<AlertDialogFooter>
						<Button
							ref={cancelRef}
							onClick={() => {
								if (uiStore.dialogData.onCancel) {
									uiStore.dialogData?.onCancel();
								}
								uiStore.clearDialog();
							}}
						>
							{Strings.general.cancel}
						</Button>
						<Button
							ml={3}
							colorScheme="red"
							onClick={() => {
								uiStore.dialogData?.onConfirm();
								uiStore.clearDialog();
							}}
						>
							{Strings.general.yes}
						</Button>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialogOverlay>
		</AlertDialog>
	);
});
