// MARK: React
import React from "react";

// MARK: Next
import Head from "next/head";
import Strings from "./Strings";

// MARK: Implementation
export interface IImage {
	url: string;
	type: "image/png";
	width: number;
	height: number;
}

export interface IProps {

	type: "blog" | "website" | "author";
	title: string;
	description: string;
	themeColor: string;
	keywords: string[];
	url: string | null;
	twitter: {
		type: "summary" | "summary_large_image";
		title?: string;
		description?: string;
		image?: IImage;
	};
	canonicalUrl?: string;
	image?: IImage;
}

const OgTags: React.FC<IProps> = (props: IProps) => {

	return (
		<Head>
			{/* MARK: Brand */}
			<meta key="theme-color" name="theme-color" content={props.themeColor} />

			{/* MARK: General */}
			<title>{props.title}</title>
			<link rel="apple-touch-icon" sizes="57x57" href="touch-icon-iphone.png" />
			<link rel="apple-touch-icon" sizes="114x114" href="touch-icon-ipad.png" />
			<meta key="description" name="description" content={props.description} />
			<meta key="keywords" name="keywords" content={props.keywords.join(",")} />
			<link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
			<link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />

			{props.twitter && (
				<>
					{/* MARK: Twitter */}
					<meta key="twitter:card" name="twitter:card" content={props.twitter.type} />
					<meta key="twitter:title" name="twitter:title" content={props.twitter.title} />
					<meta key="twitter:description" name="twitter:description" content={props.twitter.description} />
					{props.twitter.image || props.image && (
						<meta key="twitter:image" name="twitter:image" content={(props.twitter.image || props.image).url} />
					)}
				</>
			)}

			{/* MARK: OG */}
			<meta key="og:type" property="og:type" content={props.type} />
			<meta key="og:site_name" property="og:site_name" content={Strings.ogTags.name} />
			<meta key="og:title" property="og:title" content={props.title} />
			<meta key="og:description" property="og:description" content={props.description} />
			{props.url && (
				<meta key="og:url" property="og:url" content={props.url} />
			)}
			{props.image && (
				<>
					<meta key="og:image" property="og:image" content={props.image.url} />
					<meta key="og:image:type" property="og:image:type" content={props.image.type} />
					<meta key="og:image:width" property="og:image:width" content={`${props.image.width}`} />
					<meta key="og:image:height" property="og:image:height" content={`${props.image.height}`} />
				</>
			)}
		</Head>
	);
};

export default OgTags;
