import { extendTheme } from "@chakra-ui/react";

export const CustomTheme = extendTheme({
	colors: {
		base: "#669F7C",
		green: {
			900: "#576D6E",
		},
	},
	components: {
		Button: {
			baseStyle: {
				borderRadius: 0,
			},
		},
	},
});
