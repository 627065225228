// MARK: API
import * as API from "@startapp/laira-user-ssr-api";

// MARK: Libraries
import { LocalizedStringsMethods } from "localized-strings";

const productionUrl = "https://api.laira.startapp.one/user";
const stagingUrl = "https://api.laira.startapp.one/user-staging";

const bootAPI = (strings: LocalizedStringsMethods) => {
	let apiURL: string;

	switch (process.env.APP_ENV) {
		case "production": {
			apiURL = productionUrl;
			break;
		}

		case "staging":
		default: {
			apiURL = stagingUrl;
		}
	}

	API.setStrings(strings);
	API.setUrl(apiURL);
};

export default API;

export { bootAPI };
