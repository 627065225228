import React from "react";

// MARK: Next
import Head from "next/head";

// MARK: Resources
import Strings from "../../../resources/Strings";
import OgTags from "../../../resources/OgTags";

// MARK: Implementation
const HomeHead: React.FC = () => {

	const url = process.env.NEXT_PUBLIC_PAGE_URL ?
		`https://${process.env.NEXT_PUBLIC_PAGE_URL}}` :
		null;

	return (
		<>
			<OgTags
				{...Strings.pages.home.ogTags}
				url={process.env.NEXT_PUBLIC_PAGE_URL ? `https://${process.env.NEXT_PUBLIC_PAGE_URL}` : null}
			/>
			<Head>
				{url && (
					<link
						rel="canonical"
						href={url}
					/>
				)}
			</Head>
		</>
	);
};

export default HomeHead;
