import React from "react";

// MARK: Styles
import "./index.scss";
import { CustomTheme } from "../theme";

// MARK: Next
import App from "next/app";
import { default as Router } from "next/router";

// MARK: Theme
import { ChakraProvider } from "@chakra-ui/react";

// MARK: NProgress
import * as NProgress from "nprogress";
NProgress.configure({ showSpinner: false });
function nprogressStart() {
	if (typeof window !== "undefined") {
		NProgress.start();
	}
}

function nprogressEnd() {
	if (typeof window !== "undefined") {
		NProgress.done();
	}
}

Router.events.on("routeChangeStart", nprogressStart);
Router.events.on("routeChangeComplete", nprogressEnd);
Router.events.on("routeChangeError", nprogressEnd);

// MARK: Sentry
import SentryUtil from "../resources/SentryUtil";
const { captureException } = SentryUtil();

// MARK: Components
import { SiteHead } from "../components/general/SiteHead";
import { Dialog } from "../components/general/Dialog";

// MARK: API
import Strings from "../resources/Strings";
import { bootAPI } from "../resources/API";

bootAPI(Strings);

// MARK: Context
import { UIStoreProvider } from "../contexts/UIStoreContext";
import { UserAuthProvider } from "../contexts/UserAuthContext";

// MARK: Lazy loading
import { lazyload } from "react-lazyload";

lazyload({ once: true, offset: 100 });

import { enableStaticRendering } from "mobx-react-lite";
import HomeHead from "../components/general/HomeHead";
import initializeGTM from "resources/tagManager";
enableStaticRendering(typeof window === "undefined");

export default class MyApp extends App {
	public componentDidCatch(err: any, errInfo: any) {
		if (super.componentDidCatch) {
			super.componentDidCatch(err, errInfo);
		}

		captureException(err, errInfo);
	}

	public componentDidMount() {
		initializeGTM();
	}

	public render() {
		const { Component, pageProps } = this.props;

		return (
			<>
			<ChakraProvider theme={CustomTheme}>
				<UIStoreProvider>
					<UserAuthProvider>
						<SiteHead />
						<HomeHead />
						<Component {...pageProps} />
					</UserAuthProvider>
					<Dialog />
				</UIStoreProvider>
			</ChakraProvider>
			</>
		);
	}
}
