// MARK: React
import React from "react";

// MARK: Next
import Head from "next/head";

// MARK: Implementation
export const SiteHead: React.FC = () => {
	const hasGtag = true;

	return (
		<Head>
			{/* MARK: App */}
			<meta
				key="viewport"
				name="viewport"
				content="width=device-width, initial-scale=1, shrink-to-fit=no"
			/>
			<link rel="apple-touch-icon" type="image/png" href="/faviconn.ico" />
			<link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
			<link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
			{/* <script
				src="//code.tidio.co/2lfkh18e0qx6pcauvwelzk3ayqvd8qle.js"
				async={true}
			/> */}
			{/* MARK: Analytics */}
			{hasGtag ? (
				<>
					{/* tslint:disable: react-no-dangerous-html */}
					{/* Google */}
					<script
						dangerouslySetInnerHTML={{
							__html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-PLTRFTL');`,
						}}
					/>
					{/* tslint:enable: react-no-dangerous-html */}
				</>
			) : (
				<meta name="robots" content="noindex" />
			)}
		</Head>
	);
};
