// MARK: Next
import { NextPageContext } from "next";

// NOTE: This require will be replaced with `@sentry/node`
// server side thanks to the webpack config in next.config.js
import * as Sentry from "@sentry/browser";
import * as SentryIntegrations from "@sentry/integrations";
import { default as nookies } from "nookies";

const SentryUtil = (release = process.env.NEXT_PUBLIC_SENTRY_RELEASE) => {
	const sentryOptions: Sentry.BrowserOptions = {
		dsn: process.env.NEXT_PUBLIC_SENTRY_URL,
		release,
		maxBreadcrumbs: 50,
		attachStacktrace: true,
		beforeSend(event) {
			if (event.exception) {
				Sentry.showReportDialog({ eventId: event.event_id });
			}

			return event;
		},
	};

	// When we're developing locally
	if (process.env.APP_ENV !== "production") {
		// Don"t actually send the errors to Sentry
		sentryOptions.beforeSend = () => null;

		// Instead, dump the errors to the console
		sentryOptions.integrations = [
			new SentryIntegrations.Debug({
				// Trigger DevTools debugger instead of using console.log
				debugger: false,
			}),
		];
	}

	Sentry.init(sentryOptions);

	return {
		Sentry,
		captureException: (err: any, errorInfo: any, ctx?: NextPageContext) => {
			Sentry.configureScope((scope) => {
				if (err.message) {
					// De-duplication currently doesn"t work correctly for SSR / browser errors
					// so we force deduplication by error message if it is present
					scope.setFingerprint([err.message]);
				}

				if (err.statusCode) {
					scope.setExtra("statusCode", err.statusCode);
				}

				if (ctx) {
					const { req, res, query, pathname } = ctx;

					if (res && res.statusCode) {
						scope.setExtra("statusCode", res.statusCode);
					}

					if (typeof window !== "undefined") {
						scope.setTag("ssr", "false");
					} else {
						scope.setTag("ssr", "true");
					}

					scope.setExtra("url", req.url);
					scope.setExtra("method", req.method);
					scope.setExtra("headers", req.headers);
					scope.setExtra("query", query);
					scope.setExtra("pathname", pathname);

					const cookies = nookies.get(ctx);

					if (cookies.deviceId) {
						scope.setUser({ id: cookies.deviceId });
					}
				}

				if (errorInfo) {
					Object.keys(errorInfo).forEach((key) =>
						scope.setExtra(key, errorInfo[key]),
					);
				}
			});

			return Sentry.captureException(err);
		},
	};
};

export default SentryUtil;
